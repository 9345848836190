import React from 'react';
import { Card, CardContent, CardMedia, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
//import swami from '../assets/images/cards/Parenting_CMDFW_7455.jpg'


function FormCard({ title, description, route, eventName, eventImage }) {
  return (
    <Card style={{ maxWidth: 300, margin: '20px' }}>
        <CardMedia
          component="img"
        
          image={eventImage}
        />
      <CardContent>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
        <Button component={Link} to={route} variant="contained" color="primary" style={{ marginTop: '10px' }}>
          {eventName}
        </Button>
      </CardContent>
    </Card>
  );
}

export default FormCard;