import React, { useState, useEffect } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import TshirtInfoForm from './forms/TshirtInfoForm'
import SolarEclipseForm from './forms/SolarEclipseForm'
import GitaFestForm from './forms/GitaFestForm'
import JCHYKRetreatForm from './forms/JCHYKRetreatForm'
import LaborDayRetreatForm from './forms/LaborDayRetreatForm'
import ParentingWorkshopForm from './forms/ParentingWorkshopForm'
import BannerComp from './components/BannerComp'
import CampFormComp from './components/CampFormComp'
import StudentInfoComp from './components/StudentInfoComp'
import ParentInfoComp from './components/ParentInfoComp'
import SpouseInfoComp from './components/SpouseInfoComp'
import MedicalInfoComp from './components/MedicalInfoComp'
import PrimaryMemberComp from './components/PrimaryMemberComp'
import GuestComp from './components/GuestComp'
import ChildCareComp from './components/ChildCareComp'
import PaymentComp from './components/PaymentComp'
import DonationForm from "./forms/DonationForm"
import NaadaBinduForm from "./forms/NaadaBinduForm";

const DemoPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    ...theme.typography.body2,
    textAlign: 'center',
}));

export default function EventsForm() {
    const [eventData, setEventData] = useState({
        parent: {
            memberType: "parent",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 5,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        spouse: {
            memberType: "spouse",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        children: [{
            memberType: "child",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        }],
        guest: [{
            memberType: "Guest",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        }],
        transaction: {
            quantities: 1,
            itemPrice: 0,
            totalAmount: "",
            donationAmount: "",
            paymentStatus: "",
            payerId: ""
        },
        medical: {
            pcpName: "",
            pcpMobile: "",
            medicalInsurance: "",
            groupNo: "",
            idNo: "",
            medicalNotes: "",
            medicalConsentSignedBy: "",
            treatmentConsentSignedBy: ""
        },
        tshirts: {
            S: "0",
            XL: "0",
            L: "0",
            M: "0"
        },
        eventType: 'GitaFest',
        center: '',
        notes: '',
        sessionName: ""
    });
    console.log("Event data in events form :", (eventData));

    return (
        <div>

            <CssBaseline />

            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <DemoPaper variant="elevation">

                    <BannerComp eventData={eventData} setEventData={setEventData} />

                    <Grid item>
                        {eventData.eventType == "TshirtWalkathon" ?
                            <Grid item>
                                <TshirtInfoForm eventData={eventData} setEventData={setEventData} />
                            </Grid>
                            : ''}
                    </Grid>
                    <Grid item>
                        {eventData.eventType == "HighSchoolCamp" ?
                            <Grid item>
                                <Grid item>
                                    <Box sx={{ width: '100%', minWidth: 300, padding: 1 }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            <b>Where:</b>Chinmaya Mangalam | 10470 FM744, Barry TX, 75102
                                            <div><b>When: </b>MARCH 28th – MACRH 31st, 2024</div>
                                            <b>COST: </b>$200/ per Balavihar student
                                        </Typography>
                                    </Box>
                                    <div><br /></div>
                                </Grid>
                                <Grid item>
                                    <PaymentComp />
                                </Grid>
                            </Grid>
                            : ''}
                    </Grid>
                    <Grid item>
                        {eventData.eventType == "SolarEclipse" ?
                            <Grid item>
                                <SolarEclipseForm />
                            </Grid>
                            : ''}
                    </Grid>
                    <Grid item>
                        {eventData.eventType == "GitaFest2024" ?
                            <Grid item>
                                <GitaFestForm />
                            </Grid>
                            : ''}
                    </Grid>
                    <Grid item>
                        {eventData.eventType == "LaborDayRetreat" ?
                            <Grid item>
                                <LaborDayRetreatForm />
                            </Grid>
                            : ''}
                    </Grid>
                    <Grid item>
                        {eventData.eventType == "Parenting" ?
                            <Grid item>
                                <ParentingWorkshopForm />
                            </Grid>
                            : ''}
                    </Grid>
                    <Grid item>
                        {eventData.eventType == "Donation" ?
                            <Grid item>
                                <DonationForm />
                            </Grid>
                            : ''}
                    </Grid>
                    <Grid item>
                        {eventData.eventType == "NaadaBindu" ?
                            <Grid item>
                                <NaadaBinduForm />
                            </Grid>
                            : ''}
                    </Grid>
                    <Grid item>
                        {eventData.eventType == "JCHYKRetreat" ?
                            <Grid item>
                                <JCHYKRetreatForm />
                            </Grid>
                            : ''}
                    </Grid>
                </DemoPaper>
            </Container>

        </div>
    );
}