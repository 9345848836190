import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from "@mui/material/styles";
import Container from '@mui/material/Container';
import EventsForm from './EventsForm';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DonationForm from './forms/DonationForm';
import NaadaBinduForm from './forms/NaadaBinduForm';
import ChykconForm from './forms/ChykconForm';
import BannerComp from './components/BannerComp'

const DemoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'center',
}));

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <DemoPaper variant="elevation">
          <Router>
            <Routes>
              <Route path="/" element={<BannerComp />} />
              <Route path="/DonationForm" element={<DonationForm />} />
              <Route path="/NaadaBinduForm" element={<NaadaBinduForm />} />
              <Route path="/ChykconForm" element={<ChykconForm />} />
            </Routes>
          </Router>
        </DemoPaper>
      </Container>
    </div>
  );
}

export default App;
