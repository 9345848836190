import React, { useState, useEffect } from 'react';
import logo from '../assets/images/cards/Chinmaya-Mission-Logo.gif';
import parenting from '../assets/images/cards/Parenting_CMDFW_7455.jpg'
import donation from '../assets/images/cards/GuruDakshina.jpg'
import naadabindu from '../assets/images/cards/Naadabindu.jpg'
import chykcon from '../assets/images/cards/Chykcon.jpg'
import { Grid, Typography, Paper } from '@mui/material';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Tile from './Tile';
import { Link } from 'react-router-dom';
import FormCard from './FormCard';
import UserList from './FetchEvents';


export default function BannerComp({ eventData, setEventData }) {
    console.log("Event data in Banner 1 :", JSON.stringify(eventData));

    function handleChange(e) {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
    }
    function handleRegisterClick(e) {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
    }

    

    return (
        <div>
            <Grid container alignItems="center">
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6" align="center" gutterBottom>
                        {'CMDFW Event Registration System'}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <img src={logo} className="App-logo" alt="logo" />
                </Grid>

                <Grid container spacing={2} style={{ padding: '20px' }}>
                    <Grid item xs={12} sm={6}>
                        <FormCard
                        title="Donation"
                        description="Donation/ Gurudakshina"
                        route="/DonationForm"
                        eventName={"Donate"}
                        eventImage={donation}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormCard
                        title="Naada Bindu"
                        description="Naada Bindu concert"
                        route="/NaadaBinduForm"
                        eventName={"Register"}
                        eventImage={naadabindu}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormCard
                        title="CHYK CON"
                        description="Chyk Con 2024"
                        route="/ChykconForm"
                        eventName={"Register"}
                        eventImage={chykcon}
                        />
                    </Grid>
                    
                    {/* Add more cards as needed */}
                </Grid>               
                          
            </Grid>
        </div>
    );

}
